import Layout from '../../components/Layout'
import News from '../../components/News'

function Index() {
  return (
    <Layout pageTitle='Haberler'>
      <News />
    </Layout>
  )
}

export default Index