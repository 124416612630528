import React from 'react';
import { IconContext } from 'react-icons';
import { IoMenuOutline, IoClose } from 'react-icons/io5';
import { ToggleFunc } from './Nav';

interface MenuButtonProps {
  mobileMenuIsShown: boolean;
  toggle: ToggleFunc;
}

const MenuButton = (props: MenuButtonProps) => {
  const { toggle, mobileMenuIsShown } = props;

  return (
    <button onClick={toggle} className='menu-btn menu-btn-outline'>
      <IconContext.Provider value={{ size: '2rem' }}>
        {mobileMenuIsShown ? (
          <IoClose></IoClose>
        ) : (
          <IoMenuOutline></IoMenuOutline>
        )}
      </IconContext.Provider>
    </button>
  );
};

export default MenuButton;
