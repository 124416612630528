import { supabase } from "../clients/Supabase";

const tableNames = {
    Leagues: 'Leagues'
}


// Get Tahminilig data
export async function getLeagues() {
    const { error, data } = await supabase.from(tableNames.Leagues).select('*').eq('active', true).order('order', { ascending: false });

    if (error) {
        throw errorBuilder(error.code, error.message);
    }

    return data;    
}

export async function getLeagueBySlug(slug: string) {    
    const { error, data } = await supabase.from(tableNames.Leagues).select('name, slug, logo, footballApiId').eq('active', true).eq('slug', slug);

    if (error) {
        throw errorBuilder(error.code, error.message);
        
    }

    return data.length > 0 ? data[0] : null;    
}

function errorBuilder(code:string, message: string) {
    return new Error(`Error occured during the league data fetching. Error Code: ${code}. Error Message: ${message}`);
}