import React from 'react'
import Layout from '../../components/Layout'

function Contact() {
  return (
    <Layout pageTitle='İletişim'>
      <div className="container grid grid-1">
        <p>
          İletişim için e-posta adresi: 
          <br /> 
          <br /> 
          
          <a className='btn btn-primary' href="mailto:tahminilig@gmail.com">tahminilig@gmail.com</a>

        </p>

      </div>
    </Layout>
  )
}

export default Contact