import React from 'react'
import { Link } from 'react-router-dom';
import Image from './Image';

const Logo = ({width, height}: {width: number, height: number}) => {
  return (
    <Link to='/' className='logo'>
          <Image
            src='https://ucarecdn.com/345f241e-7fba-4266-9f47-fbd1bc85c867/-/preview/-/quality/smart/'
            alt='TahminiLig'
            {...{ width, height }}
          />
        </Link>
  )
}

export default Logo