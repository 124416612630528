import Layout from '../../components/Layout'
import NewsDetail from '../../components/NewsDetail'
import { useParams } from 'react-router-dom'
import useNewsRest from '../../hooks/useNewsRest';

function Detail() {
  const { slug } = useParams();
  const data = useNewsRest();
  const article = data.articles.find(x => x.slug === slug);
  return (
    <Layout pageTitle={article?.title!}>
        <NewsDetail {...article!} />
    </Layout>
  )
}

export default Detail