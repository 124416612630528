import React from 'react'
import { format, parseISO } from 'date-fns'
import {tr } from 'date-fns/locale';

const Date = ({dateString}: {dateString: string}) => {
    const date = parseISO(dateString);

  return (
    <time dateTime='dateString'>
        {format(date, 'd LLLL yyyy hh:mm', {
            locale: tr
        })}
    </time>
  )
}

export default Date