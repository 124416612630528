import React from 'react';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {

  const year = new Date().getFullYear();

  return (
    <footer className='footer'>
      <div className='container grid'>
        <div className='footer-navs'>
          <nav
            aria-labelledby='footer-nav-qucik-links-header'
            className='footer-nav-header'
          >
            <header>
              <span>Hızlı Erişim</span>
            </header>
            <ul>
              <li>
                <Link to='/competitions'>Ligler</Link>
              </li>
              <li>
                <Link to='/news'>Haberler</Link>
              </li>
              <li>
                <Link to='/profile'>Hesabım</Link>
              </li>
            </ul>
          </nav>
          <nav
            aria-labelledby='footer-nav-qucik-links-header'
            className='footer-nav-header'
          >
            <header>
              <span>Hakkında</span>
            </header>
            <ul>
              <li>
                <Link to='/about'>Tahminilig nedir?</Link>
              </li>
              <li>
                <Link to='/about/contact'>İletişim</Link>
              </li>
            </ul>
          </nav>
          <nav
            aria-labelledby='footer-nav-qucik-links-header'
            className='footer-nav-header'
          >
            <header>
              <span>Yasal</span>
            </header>
            <ul>              
              <li>
                <Link to='/about/privacy-policy'>Gizlilik Politikası</Link>
              </li>
              <li>
                <Link to='/about/terms-of-use'>Kullanım Koşulları</Link>
              </li>              
              <li>
                <Link to='/about/cookie-policy'>Çerez Politikası</Link>
              </li>              
            </ul>
          </nav>
          <nav aria-labelledby='footer-nav-qucik-links-header' className='footer-nav-header'>
            <header>      
              <span>Bizi Takip Edin</span>                      
            </header>
            <ul>
              <li>
                <SocialIcon style={{ height: 32, width: 32, marginRight: '.5rem'}} url='https://www.facebook.com/Tahminilig/' />
                <SocialIcon style={{ height: 32, width: 32, marginRight: '.5rem' }} url='https://twitter.com/tahminilig' />
              </li>
            </ul>
          </nav>
        </div>
        <div className='footer-brand'>
          <span>TahminiLig &copy; {year}</span>
        </div>
      </div>
    </footer>
  );
};


export default Footer;