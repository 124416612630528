import React from 'react'
import { ReactComponent as Spinner } from "../assets/img/loading.svg";

export const Loading = () => {
  return (
    <div className='spinner'>
        <Spinner />
    </div>
  )
}
