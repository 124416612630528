import { ReactNode, useEffect } from 'react';
import Nav, { Navigation } from './Nav';
import Footer from './Footer';
import Seo from './Seo';
import MainHeader from './MainHeader';

export default function Layout({
  children,
  pageTitle,
  logo
}: {
  children: ReactNode;
  pageTitle: string;
  logo?: string
}) {
  
  const navs:Navigation[] = [
    {
      to: '/',
      name: 'Anasayfa'
    },
    {
      to: '/news',
      name: 'Habeler'
    },
    {
      to: '/league',
      name: 'Ligler'
    },
    {
      to: '/profile',
      name: 'Hesabım'
    }
  ];

  return (
    <>
      <Seo siteMetaData={{title: pageTitle}} />
      <Nav navigations={navs} />
      <MainHeader pageTitle={pageTitle} logo={logo} />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
}
