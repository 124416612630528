import React from 'react';
import * as RadixAvatar from "@radix-ui/react-avatar";


const Avatar = ({src, alt }: {src: string, alt: string}) => {

    function parseNameLatter(name:string) {
        const nameTiles = name.trim().split(' ');

        if (nameTiles.length > 1) {
            let letters = nameTiles[0].charAt(0);
            letters += nameTiles[1].charAt(0);
            return letters;
        } 
        
        return nameTiles[0].charAt(0);        
    }

  return (
    <RadixAvatar.Root>
        <RadixAvatar.Image src={src} alt={alt}  />
        <RadixAvatar.Fallback delayMs={600}>{parseNameLatter(alt)}</RadixAvatar.Fallback>
    </RadixAvatar.Root>
  )
}

export default Avatar