import React, { useState } from 'react';

interface ImageProps {
  src: string;  
  alt: string;
  props?: any[];
}

const Image = ({ src, alt, ...props }: ImageProps) => {
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  
  function onError() {
    if (!error) {
      setError(true);      
      setImgSrc("https://ucarecdn.com/2df98e7f-342a-4ba6-a59e-b361fbb7e93f/-/preview/60x60/");
    }
  } 

  return <img src={imgSrc} alt={alt} {...props} onError={onError} loading="lazy" />;
};

export default Image;
