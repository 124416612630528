import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const AuthProvider = ({ children }: { children: any }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID!;
  const redirectUri =
    process.env.REACT_APP_AUTH0_REDIRECT_URI || window.location.origin;

  const naviagte = useNavigate();

  const onRedirectCallback = (appState: any) => {
    naviagte(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
        {children}
    </Auth0Provider>
  );
};
