import { useEffect, useState } from 'react';

import Image from './Image';
import { NewsSourceProps } from '../components/NewsCard';
import Markdown from 'markdown-to-jsx';

interface NewsDetailProps {
  title: string;
  description: string;
  urlToImage: string;
  content: string;
  source: NewsSourceProps;
  publishedAt: string;
  slug: string;
}

function NewsDetail({ title, description, urlToImage, slug }: NewsDetailProps) {
  const [articleContent, setArticleContent] = useState('#Hello');

  useEffect(() => {
    import(`../markdown/${slug}.md`).then((response) => {
      console.log(response.default);
      fetch(response.default).then(res => res.text()).then(result => setArticleContent(result)).catch(err => console.log(err));
    }).catch(err => console.log(err));
  }, [slug]);

  return (
    <article className='container grid grid-1'>
      <summary>{description}</summary>
      <Image src={urlToImage} alt={title}></Image>      
      <Markdown className='content'>{articleContent}</Markdown>      
    </article>
  );
}

export default NewsDetail;
