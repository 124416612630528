import React from 'react'
import Image from "./Image";

interface MainHeaderProps {
    pageTitle: string,
    logo?: string
}

const MainHeader = ({pageTitle, logo}: MainHeaderProps) => {
  return (
    <header className={`header ${logo ? " my-1" : " "}`}>
          <div className="container flex justify-start">
            {
                logo && <Image src={logo!} alt={pageTitle} {...{width:43, height: 43}} />
            }
              
              <h1>{pageTitle}</h1>            
          </div>
      </header>
  )
}

export default MainHeader