import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthButtons } from './AuthButtons';
import Logo from './Logo';
import MenuButton from './MenuButton';

import MobileNavMenu from './MobileNavMenu';

export interface ToggleFunc {
  (): void;
}

export interface Navigation {
  to: string;
  name: string;
}

export interface NavProps {
  navigations: Navigation[];
}

export default function Nav(props: NavProps) {
  const { navigations } = props;

  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false);

  return (
    <>
      <div className='navbar'>
        <div className='container flex'>
          <Logo width={75} height={75} />
          <nav className='hidden'>
            <ul>
              {navigations?.map((nav, index) => (
                <li key={index}>
                  <Link to={nav.to}>{nav.name}</Link>
                </li>
              ))}
              <AuthButtons />
            </ul>
          </nav>
          {/* Hamburger Menu on mobile */}
          <MenuButton toggle={() => setMobileMenuIsShown(!mobileMenuIsShown)} mobileMenuIsShown={mobileMenuIsShown} />          
        </div>
      </div>
      { mobileMenuIsShown && <MobileNavMenu navigations={navigations} /> }
    </>
  );
}
