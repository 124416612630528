import React from 'react';
import Layout from '../../components/Layout';

function Index() {
  return (
    <Layout pageTitle='Tahminilig Nedir?'>
      <div className='container grid grid-1'>
        <p>
          Tahminilig futbol maçları için skor tahmininde bulunabileceğiniz
          online oyun sitesidir.
        </p>
      </div>
    </Layout>
  );
}

export default Index;
