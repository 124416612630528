import React from 'react';
import ReactDOM from 'react-dom/client';
import './theme/utilities.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './auth/AuthProvider';

import Index from "./routes/supabase/Index";

// React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

const rootNode = document.getElementById('root') as HTMLElement;

console.log(process.env.NODE_ENV);


function renderJsx() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <BrowserRouter>          
           <AuthProvider>
            <App />
          </AuthProvider> 
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={true}></ReactQueryDevtools>
      </QueryClientProvider>
    </React.StrictMode>
  )
}


if (rootNode.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootNode,
    renderJsx()
  );
} else {
  const root = ReactDOM.createRoot(rootNode);
  root.render(
    renderJsx()
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
