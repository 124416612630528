import React from 'react'
import useHelmet from '../hooks/useHelmet';
import { SiteMeta } from '../hooks/useHelmet';


const site = {
    description: `Tahminilig futbol maçları için skor tahmininde bulunabileceğiniz, haberler ve istatistiklere göz atabileceğiniz komple bir futbol platformudur. Tahminilig futbol maçları için skor tahmininde bulunabileceğiniz, 
                  haberler ve istatistiklere göz atabileceğiniz komple bir futbol platformudur.`,
    title: "Tahminilig",
    author: "Tahminilig",
    lang: 'tr',    

}

interface SiteMetaDataProps {
    description?: string,
    title?: string,
    lang?: string,
    meta?: SiteMeta[]
}




const Seo = ({siteMetaData}: {siteMetaData?: SiteMetaDataProps}) => {
    const { description, title, lang, meta } = siteMetaData! || site;

    const pageTitle = title || site.title;    
    const metaDescription = description || site.description;

    const metaArray: Array<SiteMeta> = [
      {
          name: "description",
          content: metaDescription

      },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
  ];    

    useHelmet(site.title, pageTitle, lang!, metaArray.concat(meta!));

  return (
    <></>
)
}

export default Seo