import React from 'react'
import Date from './Date'

const TagContainer = ({name, publishedAt }: { name: string, publishedAt: string}) => {
  return (
    <div className="tag-container">
        <div className="news-source">
            <em className="icon">{name.slice(0,1)}</em>
            <span>{name}</span>
        </div>
        <Date dateString={publishedAt} />
    </div>
  )
}

export default TagContainer