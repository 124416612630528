import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export default function LogoutButton() {
    const { logout, isAuthenticated } = useAuth0();

  return (
    isAuthenticated ?
    <li className='mx-1'>
      <button className='btn btn-outline' onClick={() => logout({ returnTo: window.location.origin})}>
        Çıkış
    </button></li>
    : null
  )
}
