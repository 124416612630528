import Layout from '../components/Layout';
import TopNews from '../components/TopNews';
import News from '../components/News';
import LeagueList from '../components/LeagueList';
import { useQuery } from '@tanstack/react-query';
import { getLeagues } from '../fetch/fetch';
import { Loading } from '../components/Loading';


export default function Home() {
  const { isLoading, error, data } = useQuery<any[], Error>([
    'leagueList'],
     getLeagues
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    console.log(error);
    return <div>Maalesef bir hata oluştu. Birazdan tekrar deneyiniz. <br/> Hata Mesajı: <div style={{fontWeight: 'bold'}}>{error.message!}</div> </div>;
  }

  return (    
      <Layout pageTitle='Anasayfa'>
        <TopNews />
        <News />
        <LeagueList leagues={data} />
      </Layout>
  );
}
