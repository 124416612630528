import useNewsRest from '../hooks/useNewsRest';
import NewsCard from './NewsCard';

function News() {
    const { articles } = useNewsRest();
    const news = articles?.filter(a => !a.headline);

  return (
    <section className="cards">
        <div className="container grid">
            {
                news.length > 0 ?                
                news.map((article, i) => <NewsCard key={article.source.name + i} {...article} />)
                : <p>Haber listesi boş! </p>
            }            
        </div>
    </section>
  )
}

export default News