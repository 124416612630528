import { Route, Routes } from 'react-router-dom';
import Index from './routes/league/Index';
import NotFound from './components/NotFound';
import Home from './routes/Home';
import Profile from './routes/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from './components/Loading';
import NewsPage from "./routes/news/Index";
import NewsDetail from './routes/news/Detail';
import AboutPage from "./routes/about/Index";
import AboutDetail from "./routes/about/Detail";
import Contact from './routes/about/Contact';
import PrivacyPolicy from './routes/about/PrivacyPolicy';
import TermsOfUse from './routes/about/TermsOfUse';
import CookiePolicy from './routes/about/CookiePolicy';
import Detail from './routes/league/Detail';

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (  
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='news'>          
          <Route path=":slug" element={<NewsDetail />}></Route>
          <Route index element={<NewsPage />}></Route>
      </Route>
      <Route path='league'>
        <Route path=':slug' element={<Detail />}></Route>
        <Route index element={<Index />} />
      </Route>
      <Route path='profile' element={<Profile />}></Route>
      <Route path='about'>
          <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='terms-of-use' element={<TermsOfUse />}></Route>
          <Route path='cookie-policy' element={<CookiePolicy />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route index element={<AboutPage />}></Route>
      </Route>

      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  );
};
