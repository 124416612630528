import { useEffect } from "react";


export interface SiteMeta {
    name?: string,
    property?: string,
    content: string

}

export default function useHelmet(siteTitle:string, pageTitle: string, lang: string,  htmlTags:SiteMeta[]) {
    
    useEffect(() => {
      document.title = `${siteTitle} - ${pageTitle}`;
     
    }, [siteTitle, pageTitle, htmlTags]);
    
}