import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from '../../components/Layout'
import { Loading } from '../../components/Loading'
import { useQuery } from '@tanstack/react-query'
import { getLeagues } from '../../fetch/fetch'
import LeagueList from '../../components/LeagueList'

const  Index = () => {
  const { isLoading, error, data} = useQuery<any[], Error>(['leagueList'], getLeagues);

  if (isLoading) {
    return <Loading />
  }

  if(error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <Layout pageTitle='Ligler'>
      <p>
       Sectiginiz bir lig ile tahminilig oyununa katilabilirsiniz.
      </p>
      <LeagueList leagues={data} />
    </Layout>
  )
}


export default withAuthenticationRequired(Index, {
  onRedirecting: () => <Loading />
})
