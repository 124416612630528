import { getLeagueBySlug } from '../../fetch/fetch';
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Layout from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Detail = () => {
    const { slug } = useParams();
    const { isLoading, error, data, isFetching} = useQuery<any, Error>([`getLeagueBySwag`], async () => await getLeagueBySlug(slug!));

    if (isLoading) {
        <Loading />
    }

    if (isFetching) {
      return <Loading />
    }

    if (error) {
        return <div>Error Occured: {error.message}</div>
    }    

    const {name, logo} = data;

  return (
    <Layout pageTitle={name} logo={logo}>
        
        <p>Çok yakında {name} ile tahminde bulunabileceksiniz.</p>

    </Layout>
  )


}


export  default  withAuthenticationRequired(Detail, {
  onRedirecting: () => <Loading />,
});