import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export default function LoginButton() {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    isAuthenticated === false ?
    <li className='mx-1'>
      <button className='btn btn-primary' onClick={() => loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      }
    })}>Giriş</button></li>
    : null
  )
}
