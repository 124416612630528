import React from 'react'
import { Link } from 'react-router-dom'
import Image from './Image'

function LeagueList({leagues}: {leagues: any[]}) {
    const height = 150;
    const width = 150;
   
   function sortLeagues(league1:any, league2: any) {
        return league1.order - league2.order;
   } 

  return (
    <section className="competitions">
        <div className="container grid grid-1">
            <h2>Ligler</h2>
            <p>Oynamak istediğiniz bir lig seçiniz</p>
            <div className="competitions-list grid grid-2">
                {
                    leagues?.sort(sortLeagues).map(league => (
                        <div key={league.id} className="competition">
                            <Link to={`/league/${league.slug}`}>
                                <div className="image-wrapper">
                                    <Image src={league.logo} alt={league.name} {...{height, width}} ></Image>
                                    <p>
                                        {league.name}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>

    </section>
  )
}

export default LeagueList