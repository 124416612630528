import React from 'react';
import { Link } from 'react-router-dom';
import Image from './Image';

export interface NewsSourceProps {
    id: any,
    name: string
}

interface NewsCardProps{
    url: string,
    urlToImage: string,
    title: string,
    content: string,
    source: NewsSourceProps,
    publishedAt: string
    
}


const NewsCard = ({ url, urlToImage, title, content, source, publishedAt  }: NewsCardProps) => {


  return (
    <div className='card'>
      <div className='card-header'>
        <Link to='/news/haber-4'>
          <Image
            src={urlToImage}
            alt={title}
          ></Image>
          <h3>{title}</h3>
          <p className='card-text'>
            {content}
          </p>
        </Link>
      </div>
      <div className='card-footer'>
        <div className='tag-container'>
          <div className='news-source'>
            <em className='icon'>{source?.name.slice(0,1)}</em>
            <span>{source?.name}</span>
          </div>
          <span>{publishedAt}</span>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
