import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from '../components/Layout';
import { Loading } from '../components/Loading';
import Avatar from '../components/Avatar';

const Profile = () => {
  const { user } = useAuth0<User>();

  return (
    <Layout pageTitle='Hesabım'>      
        <div className='container grid grid-1'>
          <div className="profile-header flex justify-start gap-4">
            <div className="profile-pic-wrapper">
              {/* <Image src={user?.picture!} alt={user?.name!}></Image> */}
              <Avatar src={user?.picture!} alt={user?.name!} />
            </div>
            <h2>{user?.name}</h2>            
          </div>
          <p>Henüz tahmin oynadığınız bir lig bulunmamaktadır...</p>
        </div>       
    </Layout>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />
});
