import React from 'react'
import { Link } from 'react-router-dom'
import useNewsRest from '../hooks/useNewsRest'
import Image from './Image'
import TagContainer from './TagContainer'

const TopNews = () => {
    const data = useNewsRest();

    const headLine = data.articles?.find(x => x.headline);

  return (
    <section className="top-news">
        <div className="container grid">
            <Link to={headLine?.url!}>
                <Image src={headLine?.urlToImage!} alt={headLine?.title!}></Image>
            </Link>
            <div className="top-news-text flex">
                <Link to={headLine?.url!}>
                    <h2>{headLine?.title}</h2>
                    <p>
                        {headLine?.description}
                    </p>
                </Link>
                <TagContainer name={headLine?.source.name!} publishedAt={headLine?.publishedAt!} />
            </div>
        </div>
    </section>
  )
}

export default TopNews