
import React from 'react'
import { Link } from 'react-router-dom';
import Logo from './Logo';
import {IoClose} from 'react-icons/io5';
import { Navigation } from './Nav';

interface MobileNavProps {
    navigations: Navigation[]    
}

const MobileNavMenu = (props: MobileNavProps) => {
    const { navigations } = props;
  return (
    <div className="mobile-nav w-screen h-screen fixed top-0 left-0 overflow-y-scroll bg-light pb-6 ">
        <div className="container h-full flex flex-col justify-between">            
            {/* Bottom Section */}
            <div className="flex flex-col justify-end">
                <ul className='flex flex-col list-none gap-6 items-baseline text-xl my-5'>
                    {
                        navigations?.map((nav: Navigation, index) => (
                            <li key={index} className='block w-full py-2'>
                            <Link to={nav.to}>{nav.name}</Link>
                          </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
  )
}

export default MobileNavMenu